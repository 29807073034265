var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "paid-detail-component" }, [
    _c("div", { staticClass: "paid-name" }, [_vm._v("加班空调")]),
    _c("div", { staticClass: "paid-arrange" }, [
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("申请ID")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.orderCode)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("申请时间")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("dateFormat")(
                  _vm.data.creationDate,
                  "YYYY-MM-DD HH:mm:ss"
                )
              ) +
              "\n      "
          ),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("申请人")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.ordPersonName)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("手机号码")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.ordPersonPhone)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("报修位置")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.positionDescName)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("收费类型")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.settlementTypeStr)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-setWidth paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("收费金额")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.totalAmount || "0.00") + "￥"),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("支付时间")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            _vm._s(
              _vm._f("dateFormat")(_vm.data.payDate, "YYYY-MM-DD HH:mm:ss") ||
                "-"
            )
          ),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("微信支付单号")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.transactionId || "-")),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("支付金额")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.totalAmount || "0.00")),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("开放时长")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.timeLength || "-")),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("开票记录编号")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.invoiceBillNo || "-")),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("开票记录编号")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.invoiceBillNo || "-")),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("开发票状态")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.invoiceStatusStr || "-")),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("开票时间")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.invoiceDate || "-")),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }